import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";

const Materials = lazy(() => import("./pages/Materials"));
const QualityControl = lazy(() => import("./pages/QualityControl"));
const Payments = lazy(() => import("./pages/Payments"));
const UnReceivedBookings = lazy(() => import("./pages/UnReceivedBookings"));

const UnknownBookings = lazy(() => import("./pages/UnknownBookings"));

const Delivery = lazy(() => import("./pages/Delivery"));
const DeliveryComplete = lazy(() => import("./pages/DeliveryComplete"));
const CourierHub = lazy(() => import("./pages/CourierHub"));
const OfficeDelivery = lazy(() => import("./pages/OfficeDelivery"));
const Invoice = lazy(() => import("./pages/Invoice"));

const MultiCartons = lazy(() => import("./pages/MultiCartons"));
const FaultyAllocation = lazy(() => import("./pages/FaultyAllocation"));

const Sheets = lazy(() => import("./pages/Sheets"));

const Categories = lazy(() => import("./pages/Categories"));
const CartonBookings = lazy(() => import("./pages/CartonBookings"));
const ShipmentBookings = lazy(() => import("./pages/ShipmentBookings"));
const ShipmentCartons = lazy(() => import("./pages/ShipmentCartons"));
const RemoveCarton = lazy(() => import("./pages/RemoveCarton"));
const AddCartons = lazy(() => import("./pages/AddCarton"));
const CartonAllocation = lazy(() => import("./pages/CartonAllocation"));
const ManageBooking = lazy(() => import("./pages/ManageBooking"));
const Bookings = lazy(() => import("./pages/Bookings"));
const Cartons = lazy(() => import("./pages/Cartons"));

const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));

const Shipments = lazy(() => import("./pages/Shipments"));

const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Admins = lazy(() => import("./pages/Admins"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage.csadmin) {
  setAuthToken(localStorage.csadmin);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?.phone;

  const roles = store.getState().config?.config?.profile?.roles
    ? store.getState().config?.config?.profile?.roles
    : [];

  // const roles = ["superadmin", "admin"];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0B60A9",
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {roles?.length > 0 && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  {isPermitted(roles, ["admin", "customer_care"]) && (
                    <Route
                      exact
                      path="/customers"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Customers />}
                        />
                      }
                    />
                  )}
                  <Route
                    exact
                    path="/bookings"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Bookings />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/booking/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<ManageBooking />}
                      />
                    }
                  />
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/add-cartons/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<AddCartons />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/remove-cartons/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RemoveCarton />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipment/cartons/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentCartons />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipment/bookings/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentBookings />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/sheets/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Sheets />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/carton/booking/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CartonBookings />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/courier"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<CourierHub />}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/unknown-bookings"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<UnknownBookings />}
                      />
                    }
                  />

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )}

                  {/* {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipment/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentDetails />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/courier"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CourierHub />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )} */}

                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )} */}

                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/settings/rates"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RateSettings />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/settings/shipping-charges"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ChargeSetting />}
                        />
                      }
                    />
                  )} */}
                  {/* {["01601040108", "01614390717"].includes(id) &&
                    isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/settings/becommerce"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<BecommerceSetting />}
                          />
                        }
                      />
                    )} */}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Delivery />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryComplete />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/office-delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<OfficeDelivery />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Shipments />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/cartons"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Cartons />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/multi-cartons"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MultiCartons />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/faulty-allocation"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<FaultyAllocation />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/carton-allocation"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CartonAllocation />}
                        />
                      }
                    />
                  )}

                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/cash-counter/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CashCounter />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/office-delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<OfficeDelivery />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Shipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/multiple-shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MultipleShipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Delivery />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryComplete />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/monthly-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MonthlyReport />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/profit-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ProfitReport />}
                        />
                      }
                    />
                  )} */}

                  {isPermitted(roles, ["superadmin"]) && (
                    <Route
                      exact
                      path="/admins"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Admins />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/categories"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Categories />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/materials"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Materials />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery", "shipment"]) && (
                    <Route
                      exact
                      path="/unreceived"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<UnReceivedBookings />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery", "shipment"]) && (
                    <Route
                      exact
                      path="/quality-control"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<QualityControl />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
